import { createAction, props } from "@ngrx/store";

export const requestUrl = createAction("[Products] Request Url", props<{ obj: any }>());

export const loadProducts = createAction(
  "[Products] Load Products",
  props<{ customUrl: string; }>()
);


export const loadProductsSuccess = createAction(
  "[Products] Load Products Success",
  props<{ products: any; }>()
);

export const loadProductsFailure = createAction(
  "[Products] Load Products Failure",
  props<{ message: string; }>()
);
